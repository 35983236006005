import ProgmaticLogo from "../../common/images/ProgmaticLab.svg"
import { useLocation } from "react-router-dom"

const ActiveNavBorder = ({activeClass}) => {
    return <div className={`nav-disable ${activeClass}`}/>
}

const NavItem = ({name, path}) => {
    const location = useLocation()

    return <li className="nav-item flex-column">
        <a className='nav-link flex-column' href={`#${path}`}>
            <div>
                {name}
            </div>
            <ActiveNavBorder activeClass={location.pathname === `/${path}` ? 'nav-active' : ''}/>
        </a>
    </li>
}

export const Header = () => {
    const location = useLocation()
    console.log(location)

    return <div className="d-flex-center w-100">
        <nav className="navbar navbar-expand-lg navbar-light bg-white w-100 px-3">
            <a className='navbar-brand flex-column header-logo' href="/" >
                <img 
                    src={ProgmaticLogo} 
                    alt="ProgmaticLab" 
                />
                <ActiveNavBorder/>
            </a>
            <button 
                className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbar" 
                aria-controls="navbar" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse px-3" id="navbar">
                <ul className="navbar-nav mr-auto mt-lg-0 d-flex-between header-main">
                    <NavItem name='О&nbsp;нас' path='about'/>
                    <NavItem name='Партнеры' path='partners'/>
                    <NavItem name='Технологии' path='technology-stack'/>
                    {/* <NavItem name='Вакансии' path='vacancy'/> */}
                    <NavItem name='Продукты' path='products'/>
                    <NavItem name='Контакты' path='contacts'/>
                </ul>
            </div>
        </nav>
    </div>
}