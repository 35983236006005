import React from "react";

export const Quote = ({children, footer}) => {
    return <div>
        <blockquote className="blockquote">
            <p className="mb-0" style={{display: 'inline-block'}}>
                <img
                    src={require('../images/common/quote-left.png')}
                    alt='"'
                    style={{height: '1rem'}}
                    className="px-2"
                />
                {children}
                <img
                    src={require('../images/common/quote-right.png')}
                    alt='"'
                    style={{height: '1rem'}}
                    className="px-2"
                />
            </p>
            {
                footer && <footer class="blockquote-footer">
                    {footer}
                </footer> 
            }
        </blockquote>
    </div>
}