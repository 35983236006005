import React from "react";
import Layout from "../layout";

import XSDForgeExample from '../../common/images/xsd-forge/xsd-forge-example.png'

const DownloadButton = ({ link, text }) => {
    return <button className="my-1 mx-1 px-4 btn btn-lg btn-primary">
        <a className="nav-link" href={link}>{text}</a>
    </button>

}

const OpenOnline = () => {
    return <button className="mb-2 mx-1 px-4 btn btn-lg btn-secondary">
        <a className="nav-link" href="https://progmaticlab.com/xsd/" target="_blank">Открыть онлайн</a>
    </button>
}

const DownloadXSDForge = () => {
    return <div className="w-100 my-big">
        <div className="mx-big py-5">
            <div className="col-12 h-100 w-100 row">
                <h1 className="col-lg-6 my-5 display-1">
                    <span className="red1-text">XSD Forge —</span>
                    <br/>
                    инструмент для разработки,
                    <br/>
                    чтения, анализа
                    <br/>
                    и редактирования XSD-схем.
                </h1>
                <div className="col-lg-6 centered-example">
                    <img src={XSDForgeExample} alt="xsd-forge-exmaple-macOS" className="w-100"/>
                </div>
            </div>
            <OpenOnline/>
            <div>
                <DownloadButton
                    link="https://xsd-forge.hb.ru-msk.vkcloud-storage.ru/desktop/darwin/arm64/XSD%20Forge-darwin-arm64-1.0.0.zip"
                    text="Скачать для macOS"
                />
                <DownloadButton
                    link="https://xsd-forge.hb.ru-msk.vkcloud-storage.ru/desktop/win32/x64/XSD%20Forge-1.0.0%20Setup.exe"
                    text="Скачать для Windows"
                />
                <DownloadButton
                    link="https://xsd-forge.hb.ru-msk.vkcloud-storage.ru/desktop/linux/x64/xsd-forge-desktop-app_1.0.0_amd64.deb"
                    text="Скачать для Linux .deb"
                />
                <DownloadButton
                    link="https://xsd-forge.hb.ru-msk.vkcloud-storage.ru/desktop/linux/x64/xsd-forge-desktop-app-1.0.0-1.x86_64.rpm"
                    text="Скачать для Linux .rpm"
                />
            </div>
        </div>
    </div>
}

export const Products = () => {
    return <Layout>
        <DownloadXSDForge/>
    </Layout>
}